<template>
  <div>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: 600;
  color: #337ab7;
}
</style>
